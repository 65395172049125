<template>
    <div class="resource-view-bg">
        <h2>{{title}}</h2>
        <p>{{description}}</p>
        <img class="icon" src="../assets/triangle.svg" alt="">
        <button class="download btn btn-outline-light"><a href="https://lajosdeme.notion.site/Mercury-Protocol-Litepaper-25d71ebe3ca241ffb9e02008d9b4c2e4" target="_blank">Open</a></button>
    </div>
</template>

<script>
export default {
    name: "ResourceView",
    props: {
        title: String,
        description: String,
        link: String,
    }
}
</script>

<style scoped>
.resource-view-bg {
    background-color: var(--background-color-secondary);;
    border-radius: 20px;
    height: 380px;
    position: relative;
}

.icon {
    position: absolute;
    bottom: 25px;
    left: 25px;
    filter: var(--filter);
}

.download {
    position: absolute;
    bottom: 25px;
    right: 25px;
}

h2 {
    color: var(--text-primary-color);
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding-top: 30px;
    padding-left: 30px;
}

p {
    color: var(--text-primary-color);
    font-family: "Roboto", sans-serif;
    font-weight: 200;
    margin-top: 30px;
    margin-left: 30px;
    margin-right: 30px;
    padding-bottom: 100px;
}

button {
    color: var(--text-primary-color);
    border-color: var(--text-primary-color);;
}
button:hover {
    background-color: var(--background-color-primary);
}

a, a:hover, a:visited, a:active {
  color: inherit;
  text-decoration: none;
 }

/* extra small & small */
@media screen and (max-width: 767px) {
    .resource-view-bg {
        margin-bottom: 50px;
    }
}
</style>