<template>
  <div class="container">
    <div class="row mobile">
      <div class="col-12">
        <Vue3Lottie class="anim" :animationData="EarthJson" :speed="2" />
        <h2 class="h2-mobile">
           We are bringing <span class="mcy-title">cheap</span> and <span class="mcy-title">scalable</span> compute to AI developers
        </h2>
      </div>
    </div>

    <div class="regular">
      <div class="row top-container justify-content-center">
        <div class="col-8 col-lg-7 col-xl-7 title-container">
          <h2>
            <span class="mcy-title">Mercury</span> is bringing cheap and scalable compute to AI developers
          </h2>
        </div>
        <div class="col-4 col-lg-5 col-xl-5">
          <Vue3Lottie class="anim" :animationData="EarthJson" :speed="2" />
        </div>
      </div>
    </div>
    <div class="row middle-container-two justify-content-center">
      <div class="col-4 filler"></div>
      <div class="col-lg-8 col-xl-8 col-md-8 col-12">
        <h3>This is the decade of AI.</h3>
        <h4>
          We make sure it benefits everyone, not just big tech.
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
import EarthJson from "../animations/newtop.json";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Top",
  data() {
    return {
      EarthJson,
    };
  },
};
</script>

<style scoped>
h1 {
  color: var(--text-primary-color);
  font-family: "Exan", sans-serif;
  font-weight: 800;
  font-size: 45px !important;
  text-align: center;
}

h2 {
  color: var(--text-primary-color);
  font-family: "Exan", "Orbitron", sans-serif;
  font-weight: 500;
  font-size: 55px !important;
}
h3 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 50px !important;
  text-align: right;
}

h4 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 25px !important;
  text-align: right;
  line-height: 40px;
}

h5 {
  font-family: 'Bungee Hairline', sans-serif;
  text-transform: uppercase;
  font-size: 23px !important;
  color: var(--text-primary-color);
  text-align: center;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 20px !important;
  color: var(--text-primary-color);
}

.top-container {
  margin-top: 100px;
}

.middle-container-two,
.middle-container-three {
  margin-top: 350px;
}

.title-container {
  z-index: 10 !important;
}

.anim {
  zoom: 2;
  -moz-transform: scale(2);
  transform: scale(2);
  margin-top: -10px !important;
}

.section-title {
  text-align: left;
  margin-top: 120px;
  line-height: 80px;
}

.mobile {
  display: none;
}

.col-6 {
  margin-bottom: 50px;
}

.h2-mobile {
  padding-top: 70px;
}

.mcy-title {
  color: var(--accent-color);;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  .col-12 {
    position: relative;
  }
  .mobile {
    display: block;
    margin-top: 100px !important;
  }

  .regular {
    display: none;
  }

  .anim {
    zoom: 2;
    -moz-transform: scale(2);
    transform: scale(2);
    margin-top: 0px !important;
  }

  .filler {
    display: none;
  }

  .middle-container-two,
  .middle-container-three {
    margin-top: 130px;
  }

  h1 {
    font-size: 35px !important;
  }

  h2 {
    position: absolute;
    top: 40%;
    transform: translateY(-40%);
    left: 0;
    font-size: 40px !important;
    text-align: center;
  }

  h3 {
    text-align: center;
    font-size: 30px !important;
  }

  h4 {
    padding-left: 0px;
    text-align: center;
    font-size: 18px !important;
    line-height: 25px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .anim {
    zoom: 3;
    -moz-transform: scale(3);
    transform: scale(3);
    margin-top: 0px !important;
  }

  .middle-container-two,
  .middle-container-three {
    margin-top: 200px;
  }

  h1 {
    font-size: 35px !important;
  }

  h2 {
    font-size: 40px !important;
    padding-top: 30px;
  }

  h3 {
    font-size: 35px !important;
  }
  h4 {
    font-size: 20px !important;
    line-height: 30px;
    padding-left: 50px;
  }
  h5 {
    font-size: 12px !important;
  }
}

/* large */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  h2 {
    font-size: 40px !important;
  }
  h1 {
    font-size: 45px !important;
  }
  h3 {
    font-size: 35px !important;
  }
  h4 {
    font-size: 20px !important;
    line-height: 30px;
    padding-left: 50px;
  }
  h5 {
    font-size: 12px !important;
  }
}
</style>