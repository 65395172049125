<template>
  <div class="title-container">
    <div class="left-mark"></div>
    <h1>{{ title }}</h1>
    <div class="right-mark"></div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Title",
  props: {
    title: String,
  },
};
</script>

<style scoped>
h1 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 45px !important;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.title-container {
  position: relative;
  width: fit-content;
}
.left-mark {
  position: absolute;
  top: 0;
  left: 0;
  border-left: 3px solid var(--text-primary-color);
  border-top: 3px solid var(--text-primary-color);
  width: 30px;
  height: 30px;
}

.right-mark {
  position: absolute;
  bottom: 0;
  right: 0;
  border-right: 3px solid var(--text-primary-color);
  border-bottom: 3px solid var(--text-primary-color);
  width: 30px;
  height: 30px;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 25px !important;
  }

  .left-mark {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }

  .right-mark {
    width: 20px;
    height: 20px;
    border-width: 2px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 30px !important;
  }
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  h1 {
    font-size: 30px !important;
  }
}
</style>
