<template>
  <div
    :class="[
      idx == 0 ? 'sec-1' : idx == 1 ? 'sec-2' : 'sec-3',
      bigSectionMargin ? 'big-margin' : '',
    ]"
    class="row section-container"
  >
    <div
      class="col-lg-6 col-xl-6 col-12 tite-container first-el"
      :class="leftAlign ? 'el-1' : 'el-2'"
    >
      <div class="inside-container">
        <h3
          :class="[
            smallMargin ? 'margin-small-section-title' : 'section-title',
            idx == 0 ? 'txt-1' : idx == 1 ? 'txt-2' : 'txt-3',
          ]"
          :style="{ textAlign: leftAlign ? 'left' : 'right' }"
        >
          {{ title }}
        </h3>
        <h3 class="section-title-centered">
          {{ title }}
        </h3>
        <p :style="{ textAlign: leftAlign ? 'left' : 'right' }">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <div
      class="col-lg-6 log-xl-6 col-12 second-el"
      :class="leftAlign ? 'el-2' : 'el-1'"
    >
      <Vue3Lottie
        class="anim-2"
        :animationData="animation"
        :speed="1.5"
        :class="[
          scale == 'lg'
            ? 'scale-anim-lg'
            : scale == 'md'
            ? 'scale-anim-md'
            : 'scale-anim-sm',
          lastAnim ? 'last-anim' : '',
        ]"
      />
    </div>
  </div>

  <div class="section-container-md">
    <div
      class="col-12 tite-container first-el"
      :class="leftAlign ? 'el-1' : 'el-2'"
    >
      <div class="row">
        <h3
          class="section-title col-6"
          :style="{ textAlign: leftAlign ? 'left' : 'right' }"
        >
          {{ title }}
        </h3>
        <h3 class="section-title-centered">
          {{ title }}
        </h3>
        <p class="col-6" :style="{ textAlign: leftAlign ? 'left' : 'right' }">
          {{ subtitle }}
        </p>
      </div>
    </div>
    <div class="col-md-12 second-el" :class="leftAlign ? 'el-2' : 'el-1'">
      <Vue3Lottie
        class="anim-2"
        :animationData="animation"
        :speed="1.5"
        :class="
          scale == 'lg'
            ? 'scale-anim-lg'
            : scale == 'md'
            ? 'scale-anim-md'
            : 'scale-anim-sm'
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Section",
  props: {
    title: String,
    subtitle: String,
    animation: Object,
    scale: String,
    leftAlign: Boolean,
    isTop: Boolean,
    smallMargin: Boolean,
    idx: Number,
    bigSectionMargin: Boolean,
    lastAnim: Boolean,
  },
};
</script>

<style scoped>
h3 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-size: 38px !important;
  text-align: right;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 20px !important;
  color: var(--text-primary-color);
}

.first-el {
  position: relative;
}

.sec-1 {
  margin-top: -100px;
}
.sec-2 {
  margin-top: -70px;
}

.sec-2.big-margin {
  margin-top: 150px !important;
}

.sec-3 {
  margin-top: 100px;
}

.inside-container {
  position: absolute;
  top: 35%;
  -ms-transform: translateY(-25%);
  transform: translateY(-25%);
}

.section-title {
  text-align: left;
  margin-top: 130px;
  line-height: 50px;
}

  .sec-3.big-margin > .tite-container > .inside-container > .section-title {
    margin-top: 50px;
  }

.margin-small-section-title {
  text-align: left;
  margin-top: 50px;
  line-height: 50px;
}

.section-title-centered,
.section-container-md {
  display: none;
}

.scale-anim-lg {
  zoom: 0.9;
  transform: scale(0.9);
  -moz-transform: scale(0.9);
}

.scale-anim-md {
  zoom: 0.7;
  transform: scale(0.7);
  -moz-transform: scale(0.7);
  padding-top: 110px;
}

.scale-anim-sm {
  zoom: 0.5;
  transform: scale(0.5);
  -moz-transform: scale(0.5);
}

.el-2 {
  order: 2;
}
.el-1 {
  order: 1;
}

@media screen and (max-width: 349px) {
  .scale-anim-sm {
    padding-top: 300px;
  }
  .scale-anim-md {
    margin-top: 100px;
  }
  .scale-anim-lg {
    padding-top: 250px !important;
  }
  .margin-small-section-title {
    display: none;
  }
  .sec-2.big-margin > .second-el > .anim-2 {
    margin-top: 10px !important;
  }
  .last-anim {
    zoom: 0.9;
    transform: scale(0.9);
    -moz-transform: scale(0.9);
    margin-top: 30px !important;
  }
}
@media screen and (min-width: 350px) and (max-width: 467px) {
  .scale-anim-sm {
    padding-top: 100px;
  }
  .scale-anim-md {
    margin-top: 50px;
  }
  .margin-small-section-title {
    display: none;
  }

  .last-anim {
    zoom: 0.8;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    margin-top: 150px !important;
  }

  .sec-2.big-margin > .second-el > .anim-2 {
    margin-top: 50px !important;
  }
}
/* extra small & small */
@media screen and (max-width: 767px) {
  .section-title {
    display: none;
  }

  .section-container {
    margin-top: 100px;
  }

  .section-title-centered {
    font-size: 30px !important;
    text-align: center;
    line-height: 40px;
    display: block;
  }

  .first-el {
    order: 1;
  }
  .second-el {
    order: 2;
    text-align: center;
  }
  .margin-small-section-title {
    display: none;
  }

  p {
    padding-left: 0px;
    text-align: center !important;
    font-size: 18px !important;
    line-height: 25px;
  }

  .scale-anim-md {
    zoom: 0.8;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
    padding-top: 110px;
  }

  .scale-anim-lg {
    zoom: 1;
    transform: scale(1);
    -moz-transform: scale(1);
    padding-top: 150px;
    margin-top: 40px;
  }

  .sec-2.big-margin > .second-el > .anim-2 {
    margin-top: 0;
  }

  .last-anim {
    zoom: 0.6;
    transform: scale(0.6);
    -moz-transform: scale(0.6);
    margin-top: 150px !important;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .section-container {
    display: none;
  }
  .section-container-md {
    display: block;
    margin-top: 10px;
  }

  .section-title {
    margin-top: 0;
    font-size: 30px !important;
    line-height: 35px;
    text-align: left !important;
  }

  p {
    text-align: left !important;
  }

  .scale-anim-sm {
    zoom: 0.4;
    transform: scale(0.4);
    -moz-transform: scale(0.4);
    margin-top: -480px;
  }
  .scale-anim-md {
    zoom: 0.5;
    transform: scale(0.5);
    -moz-transform: scale(0.5);
    margin-top: -300px;
  }

  .scale-anim-lg {
    zoom: 0.8;
    transform: scale(0.8);
    -moz-transform: scale(0.8);
  }
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .section-title {
    font-size: 30px !important;
    line-height: 35px;
  }
  .txt-1 {
    margin-top: 80px;
  }
  .txt-2 {
    margin-top: 100px;
  }
  .section-title {
    display: block;
  }
  .sec-3.big-margin {
    margin-top: 200px !important;
  }
  .last-anim {
    margin-top: 30px !important;
    zoom: 0.7;
    transform: scale(0.7);
    -moz-transform: scale(0.7)
  }
  .sec-2.big-margin > .tite-container > .inside-container > .margin-small-section-title {
    margin-top: 10px;
  }
  .sec-3.big-margin > .tite-container > .inside-container > .section-title {
    margin-top: 60px;
  }
}
</style>