<template>
  <div class="container ecosystem-container">
    <h2 class="ecosystem-title">The Mercury Ecosystem</h2>
    <Vue3Lottie class="anim-3" :animationData="Ecosystem" :speed="1" />
    <div class="row ecosys-desc-container">
      <div class="col-lg-6 col-xl-6 col-12">
        <Title class="ecosystem-part-title" title="Compute Marketplace" />
        <p>
          The Mercury Compute Marketplace will be a permissionless and
          decentralized network of compute providers. Anyone can buy access to
          compute power on the network.
          <br /><br />
          By utilizing hardware and software technologies such as confidential
          computing and cryptography, we can verify that the data and the model
          are always intact. We can verify that the computation actually has
          been carried out, and the network can compensate the provider
          accordingly.
        </p>
      </div>
      <div class="col-lg-6 col-xl-6 col-12">
        <Title
          class="ecosystem-part-title"
          title="Compute Providers and Renters"
        />
        <p>
          Compute providers are entities with underutilized GPU and CPU power
          who run Mercury Software to access the compute network. They run
          computation workloads on private data in a secure environment and get
          compensated for it.
          <br /><br />
          Compute renters are engineers, scientists, and startups who need
          access to secure and cheap computing power to train an AI model or
          carry out data science tasks.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Title from "./Title.vue";
import Ecosystem from "../animations/ecosystem.json";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Ecosystem",
  components: {
    Title,
  },
  data() {
    return {
      Ecosystem,
    };
  },
};
</script>

<style scoped>
h2 {
  color: var(--text-primary-color);
  font-family: "Exan", sans-serif;
  font-weight: 700;
  font-size: 58px !important;
  padding-top: 80px;
}

p {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-size: 20px !important;
  color: var(--text-primary-color);
}

.ecosystem-container {
  margin-top: 350px;
}

.ecosystem-title {
  text-align: center;
}

.ecosystem-part-title {
  margin-bottom: 30px;
}

.anim-3 {
  zoom: 0.8;
  -moz-transform: scale(0.55);
  transform: scale(0.55);
}

.ecosys-desc-container {
  margin-top: -50px;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  h2 {
    font-size: 40px !important;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 15px !important;
    color: var(--text-primary-color);
  }

  .ecosystem-container {
    margin-top: 100px;
  }

  .anim-3 {
    zoom: 1;
    -moz-transform: scale(1);
    transform: scale(1);
    margin-top: 30px;
  }

  .ecosys-desc-container {
    margin-top: 50px;
  }

  .ecosystem-part-title {
    margin-bottom: 10px;
    margin-top: 30px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ecosystem-container {
    margin-top: 150px;
  }

  h2 {
    font-size: 45px !important;
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 18px !important;
    color: var(--text-primary-color);
  }

  .anim-3 {
    zoom: 0.7;
    -moz-transform: scale(0.7);
    transform: scale(0.7);
    margin-top: 50px;
    margin-bottom: 90px;
  }

  .ecosystem-part-title {
    margin-bottom: 10px;
    margin-top: 30px;
  }
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  h2 {
    font-size: 45px !important;
  }
  p {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-size: 18px !important;
    color: var(--text-primary-color);
  }

  .ecosystem-container {
    margin-top: 200px;
  }
}
</style>