<template>
  <div>
    <Header @theme-change="handleThemeChange" />
    <Top />
    <SectionContainer />
    <Ecosystem />
    <CTA />
    <Resources />
    <Footer :theme="theme" />
    <Ellipses :theme="theme" />
  </div>
</template>

<script>
import Header from "../components/Header.vue";
import Top from "../components/Top.vue";
import SectionContainer from "../components/SectionContainer.vue";
import Ecosystem from "../components/Ecosystem.vue";
import CTA from "../components/CTA.vue";
import Resources from "../components/Resources.vue";
import Footer from "../components/Footer.vue";
import Ellipses from "../components/Ellipses.vue";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Home",
  components: {
    Header,
    Top,
    SectionContainer,
    Ecosystem,
    CTA,
    Resources,
    Footer,
    Ellipses,
  },
  data() {
    return {
      theme: 'light-theme'
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    const theme = localStorage.getItem("theme");
    if (theme != null) {
      this.theme = theme;
    } else {
      localStorage.setItem("theme", "light-theme")
    }
  },
  beforeUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
  methods: {
    handleThemeChange(theme) {
      this.theme = theme;
    },
     handleBeforeUnload() {
      localStorage.setItem("theme", null);
     }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bungee+Hairline&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Orbitron&display=swap');

html,
body {
  background-color: var(--background-color-primary) !important;
  overflow-x: hidden !important;
}

:root {
  --background-color-primary: white;
  --background-color-secondary: #e3f7ff;
  --text-primary-color: black;
  --accent-color: #002fa8;
  --element-size: 4rem; /* size of theme toggle */
  --toggle-color: #002fa8;
  --filter: invert(1);
}

:root.dark-theme {
  --background-color-primary: #000a1e;
  --background-color-secondary: #2d2d2d;
  --text-primary-color: white;
  --accent-color: red;
  --element-size: 4rem; /* size of theme toggle */
  --toggle-color: #002fa8;
  --filter: initial;
}
</style>