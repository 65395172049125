<template>
  <div class="container main-section-container">
    <h2 class="phase-title">
      <span :style="{ color: 'var(--accent-color)' }">Opening up access to computing power</span></h2>
    <Section
      title="Training complex AI models is compute-intensive"
      subtitle="Cost is a serious barrier to entry since GPUs are expensive and cloud providers such as AWS charge insane margins."
      :animation="AIOne"
      :leftAlign="true"
      scale="md"
      :idx="0"
    />
    <Section
      title="A lot of available computing power is underutilized"
      subtitle="Mercury will tap into the GPU & CPU power of individuals. Using our software they can run proprietary AI workloads on sensitive data, keeping both the model and the data private."
      :animation="AI2"
      scale="lg"
      :smallMargin="true"
      :bigSectionMargin="true"
      :idx="1"
    />
    <Section
      title="Mercury is where private data meets decentralized compute"
      subtitle="We put valuable private data and computing power in the hands of AI developers, open up new revenue streams for data and compute providers, and usher in an era of abundance enabled by AI."
      :animation="AI3"
      scale="sm"
      :leftAlign="true"
      :idx="2"
      :bigSectionMargin="true"
      :lastAnim="true"
    />
    <h2 class="end-title">
      <span :style="{ color: 'var(--accent-color)' }">Every single GPU</span> on
      the planet can be integrated into the decentralized compute network of
      <span :style="{ color: 'var(--accent-color)' }">the Mercury Protocol</span
      >.
    </h2>
  </div>
</template>

<script>
import Section from "./Section.vue";
import Blocks from "../animations/anim1.json";
import Data3 from "../animations/anim2.json";
import Data1 from "../animations/anim3.json";
import AIOne from "../animations/anim4.json";
import AI2 from "../animations/anim5.json";
import AI3 from "../animations/anim7.json";

export default {
  name: "SectionContainer",
  components: {
    Section,
  },
  data() {
    return {
      Blocks,
      Data1,
      Data3,
      AIOne,
      AI2,
      AI3,
    };
  },
};
</script>

<style scoped>
.end-title {
  font-family: "Exan", sans-serif;
  font-size: 40px;
  color: var(--text-primary-color);
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
  margin-top: 150px;
}
.phase-title {
  text-align: center;
  font-size: 45px;
  font-family: "Exan", sans-serif;
  font-size: 40px;
  color: var(--text-primary-color);
  margin-top: 300px;
}

/* extra small */
@media screen and (max-width: 575px) {
  h2 {
    font-size: 28px !important;
  }
  .end-title {
    margin-left: -10px;
    margin-right: -10px;
  }
  .phase-title {
    margin-top: 200px;
  }
}

/* extra small & small */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .main-section-container {
    margin-top: 100px;
  }
  h2 {
    font-size: 30px !important;
  }
  .phase-title {
    margin-top: 200px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .main-section-container {
    margin-top: 200px;
  }

  h2 {
    font-size: 35px !important;
  }
  .phase-title {
    margin-top: 50px;
    margin-bottom: 80px;
  }
}
/* large */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  h2 {
    font-size: 45px !important;
  }
}
</style>