<template>
  <div class="container cta-container">
    <iframe name="dump" ref="dump" style="display: none"></iframe>
    <div class="row">
      <div class="col-xl-2"></div>
      <div class="col-lg-6 col-xl-4 col-12">
        <h1>Join the movement</h1>
        <p>
          You will get early beta access and we will send you occasional updates
        </p>
      </div>
      <div class="col-lg-5 col-xl-4 col-12">
        <form
          action="https://mercuryprotocol.us17.list-manage.com/subscribe/post-json"
          method="get"
          target="dump"
          @submit="onSubmit"
        >
          <input type="hidden" name="u" value="29afc48cb17fbb4de210a7c9f" />
          <input type="hidden" name="id" value="5906a4712d" />
          <input type="hidden" name="c" value="?" />
          <div class="row cta-el justify-content-center">
            <input type="text" placeholder="Name" ref="name" name="MERGE1" />
          </div>
          <div class="row cta-el justify-content-center">
            <input
              type="email"
              ref="email"
              name="MERGE0"
              placeholder="E-mail"
            />
          </div>
          <div class="row cta-el justify-content-center">
            <button>Subscribe</button>
          </div>
        </form>
        <h4 id="sub-result" ref="subres">Thanks for subscribing!</h4>
      </div>
      <div class="col-xl-2"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CTA",
  methods: {
    onSubmit() {
      const res = this.$refs.subres
      if (!this.validateEmail(this.$refs.email.value)) {
        res.style.color = 'red'
        res.innerHTML = "Please enter a valid email"
        res.style.display = "block";
      } else {
        res.style.color = "#40eeec";
        res.innerHTML = "Thanks for subscribing!"
        res.style.display = "block";
      }
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
  },
};
</script>

<style scoped>
.cta-container {
  margin-top: 100px;
  position: relative;
  z-index: 10 !important;
}
h1 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 50px !important;
}
h4 {
  text-align: center;
  margin-top: 20px;
}
p {
  font-family: "Poppins", sans-serif;
  color: var(--text-primary-color);
  font-weight: 100;
  font-size: 18px;
  margin-right: 80px;
}

input {
  background-color: var(--background-color-secondary);
  height: 65px;
  border: 0;
  border-radius: 10px;
  margin-bottom: 15px;
  color: var(--text-primary-color);
}

button {
  height: 65px;
  border-radius: 10px;
  background-color: #40eeec;
  border: 0;
}

#sub-result {
    color: #40eeec;
    display: none;
}

/* extra small & small */
@media screen and (max-width: 767px) {
  .col-2 {
    display: none;
  }
  h1 {
    font-size: 40px !important;
    text-align: center;
  }
  p {
    font-size: 15px;
    text-align: center;
    margin-right: 0;
  }
  input {
    height: 50px;
    border-radius: 8px;
    width: 80%;
  }
  button {
    height: 50px;
    border-radius: 8px;
    width: 80%;
  }

  .cta-container {
    margin-top: 150px;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .col-2 {
    display: none;
  }
  h1 {
    font-size: 40px !important;
    text-align: center;
  }
  p {
    font-size: 15px;
    text-align: center;
    margin-right: 0;
  }
  input {
    height: 50px;
    border-radius: 8px;
    width: 60%;
  }
  button {
    height: 50px;
    border-radius: 8px;
    width: 60%;
  }

  .cta-container {
    margin-top: 200px;
  }
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .cta-container {
    margin-top: 150px;
  }
}
</style>