<template>
  <div>
    <img
      class="ellipse"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="userTheme == 'dark-theme' ? getEllipseDark(1) : getEllipseLight(1)"
      alt=""
    />
    <img
      class="ellipse2"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(2) : getEllipseLight(2)
      "
      alt=""
    />
    <img
      class="ellipse3"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(1) : getEllipseLight(1)
      "
      alt=""
    />
    <img
      class="ellipse4"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(2) : getEllipseLight(2)
      "
    />
    <img
      class="ellipse5"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(1) : getEllipseLight(1)
      "
      alt=""
    />
    <img
      class="ellipse6"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(2) : getEllipseLight(2)
      "
    />
    <img
      class="ellipse7"
      :class="userTheme != 'dark-theme' ? 'light-ellipse' : ''"
      v-lazy="
        userTheme == 'dark-theme' ? getEllipseDark(1) : getEllipseLight(1)
      "
      alt=""
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Ellipses",
  props: {
    theme: String,
  },
  data() {
    return {
      userTheme: String,
    };
  },
  mounted() {
    if (this.theme == null) {
      this.userTheme = localStorage.getItem("theme");
    } else {
      this.userTheme = this.theme;
    }
  },
  methods: {
    getEllipseLight(num) {
      console.log("getting light");
      if (this.theme == null) {
        this.userTheme = localStorage.getItem("theme");
      }
      if (this.userTheme == "dark-theme") {
        if (num == 1) {
          return require("@/assets/ellipse.png");
        } else if (num == 2) {
          return require("@/assets/ellipse2.png");
        }
      }

      if (num == 1) {
        return require("@/assets/ellipse1-light.png");
      } else if (num == 2) {
        return require("@/assets/ellipse2-light.png");
      }
    },
    getEllipseDark(num) {
      console.log("getting dark");
      if (this.theme == null) {
        this.userTheme = localStorage.getItem("theme");
      }
      if (num == 1) {
        return require("@/assets/ellipse.png");
      } else if (num == 2) {
        return require("@/assets/ellipse2.png");
      }
    },
  },
};
</script>

<style scoped>
.ellipse {
  position: absolute;
  top: 500px;
  left: 0;
  z-index: 0;
  height: 110%;
}

.ellipse2 {
  position: absolute;
  top: 1400px;
  right: 0;
  z-index: 0;
  height: 110%;
}

.ellipse3 {
  position: absolute;
  top: 3220px;
  left: 0;
  z-index: 0;
  height: 110%;
}

.ellipse4 {
  position: absolute;
  top: 3900px;
  right: 0;
  z-index: 0;
  height: 110%;
}

.ellipse5 {
  position: absolute;
  top: 3850px;
  left: 0;
  z-index: 0;
  height: 110%;
  display: none;
}
.ellipse6 {
  position: absolute;
  top: 3850px;
  right: 0;
  z-index: 0;
  height: 110%;
  display: none;
}

.ellipse7 {
  position: absolute;
  top: 5050px;
  left: 0;
  z-index: 0;
  height: 110%;
  display: block;
}

.light-ellipse {
  height: 100% !important;
  width: 20% !important;
  opacity: 70%;
}

/* large */
@media screen and (min-width: 991px) and (max-width: 1200px) {
  .ellipse {
    top: 330px;
  }
  .ellipse2 {
    top: 1100px;
    height: 85%;
  }
  .ellipse3 {
    top: 2500px;
  }
  .ellipse4 {
    position: absolute;
    top: 3300px;
    right: 0;
    z-index: 0;
    height: 110%;
  }

  .ellipse5 {
    position: absolute;
    top: 3300px;
    left: 0;
    z-index: 0;
    display: none;
    height: 100%;
  }
  .ellipse6 {
    position: absolute;
    top: 3300px;
    right: 0;
    z-index: 0;
    height: 100%;
    display: none;
  }
  .ellipse7 {
    position: absolute;
    top: 4600px;
    left: 0;
    z-index: 0;
    height: 110%;
    display: block;
  }
  .light-ellipse {
    height: 90% !important;
    width: 30% !important;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  .ellipse {
    top: 380px;
    left: -50px;
    height: 90%;
  }
  .ellipse2 {
    top: 1000px;
    height: 85%;
  }
  .ellipse3 {
    top: 2400px;
    height: 85%;
  }
  .ellipse4 {
    position: absolute;
    top: 3500px;
    right: 0;
    z-index: 0;
    height: 110%;
  }
  .ellipse5 {
    position: absolute;
    top: 5000px;
    left: 0;
    z-index: 0;
    height: 85%;
    display: block;
  }
  .ellipse6 {
    position: absolute;
    top: 5000px;
    right: 0;
    z-index: 0;
    height: 85%;
    display: block;
  }
  .ellipse7 {
    position: absolute;
    top: 6300px;
    left: 0;
    z-index: 0;
    height: 85%;
    display: none;
  }
  .light-ellipse {
    height: 90% !important;
    width: 40% !important;
  }
}

/* small */
@media screen and (min-width: 576px) and (max-width: 767px) {
  .ellipse {
    top: 300px;
    left: -50px;
    height: 90%;
  }
  .ellipse2 {
    top: 1100px;
    height: 85%;
  }
  .ellipse3 {
    top: 2350px;
    left: -50px;
    height: 90%;
  }
  .ellipse4 {
    position: absolute;
    top: 3300px;
    right: 0;
    z-index: 0;
    height: 110%;
  }
  .ellipse5 {
    position: absolute;
    top: 4500px;
    left: 0;
    z-index: 0;
    height: 85%;
    display: block;
  }
  .ellipse6 {
    position: absolute;
    top: 4500px;
    right: 0;
    z-index: 0;
    height: 85%;
    display: block;
  }
  .ellipse7 {
    position: absolute;
    top: 6000px;
    left: 0;
    z-index: 0;
    height: 75%;
    display: none;
  }

  .light-ellipse {
    height: 90% !important;
    width: 30% !important;
  }
}

/* extra small */
@media screen and (max-width: 575px) {
  .ellipse {
    left: -50px;
    height: 90%;
    top: 400px !important;
  }

  .ellipse.light-ellipse {
    height: 80% !important;
    width: 40% !important;
  }
  .ellipse2 {
    top: 1500px;
    height: 85%;
  }
  .ellipse2.light-ellipse {
    top: 1700px;
    height: 80% !important;
    width: 40% !important;
  }
  .ellipse3 {
    top: 3150px;
    left: -50px;
    height: 90%;
  }
  .ellipse3.light-ellipse {
    top: 2650px;
    height: 80% !important;
    width: 40% !important;
  }
  .ellipse4 {
    position: absolute;
    top: 3800px;
    right: 0;
    z-index: 0;
    height: 110%;
  }
  .ellipse4.light-ellipse {
    top: 4400px;
    height: 80% !important;
    width: 40% !important;
  }
  .ellipse5 {
    position: absolute;
    top: 5300px;
    left: 0;
    z-index: 0;
    height: 85%;
    display: block;
  }
  .ellipse5.light-ellipse {
    top: 5300px;
    height: 80% !important;
    width: 40% !important;
    display: none;
  }
  .ellipse6 {
    position: absolute;
    top: 5800px;
    right: 0;
    z-index: 0;
    height: 85%;
    display: none;
  }

  .ellipse7 {
    position: absolute;
    top: 6500px;
    left: 0;
    z-index: 0;
    height: 75%;
    display: none;
  }
}
/* extra small */
@media screen and (min-width: 400px) and (max-width: 575px) {
  .ellipse7 {
    position: absolute;
    top: 695 0px;
    left: 0;
    z-index: 0;
    height: 70%;
  }
}

@media screen and (max-width: 365px) {
  .ellipse7 {
    position: absolute;
    top: 7200px;
    left: 0;
    z-index: 0;
    height: 70%;
  }
  .ellipse5 {
    top: 5700px !important;
  }
}
</style>