<template>
  <div class="container footer-container">
    <h1>Take the power back.</h1>
    <div class="row top-row">
      <div class="col-2"></div>
      <div class="link-container col-lg-4 col-xl-4 col-6">
        <h3>Resources</h3>
        <p>
          <a href="" target="_blank">
            Technical Litepaper</a
          >
        </p>
      </div>
      <div class="link-container col-lg-4 col-xl-4 col-6">
        <h3>Get in touch</h3>
        <p><a href="mailto:founders@mercuryprotocol.io"> E-mail</a></p>
        <p>
          <a href="https://twitter.com/mcyprotocol" target="_blank"> Twitter</a>
        </p>
        <p>
          <a href="https://github.com/mercury-protocol" target="_blank">
            Github</a
          >
        </p>
      </div>
      <div class="col-2"></div>
    </div>
    <div class="separator"></div>
    <div class="copyright">
      <h4>
        Mercury Labs Ltd. {{ new Date().getFullYear() }} All rights reserved.
      </h4>
    </div>
    <img
      :class="this.userTheme == 'dark-theme' ? '' : 'light-ellipse'"
      class="ellipse4"
      :src="this.userTheme == 'light-theme' ? getEllipseLight() : getEllipseDark()"
      alt=""
    />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Footer",
  props: {
    theme: String,
  },
  data() {
    return {
      userTheme: String,
    }
  },
  mounted() {
    if (this.theme == null) {
      this.userTheme = localStorage.getItem("theme");
      } else {
        this.userTheme = this.theme;
      }
  },
  methods: {
    getEllipseDark() {
      if (this.theme == null) {
        this.userTheme = localStorage.getItem("theme");
      }
      return require("@/assets/ellipse4.png");
    },
    getEllipseLight() {
      if (this.theme == null) {
        this.userTheme = localStorage.getItem("theme");
      }
      return require("@/assets/ellipse4-light.png");
    },
  },
};
</script>

<style scoped>
.container {
  margin-top: 300px;
}
.top-row {
  margin-top: 140px;
  margin-bottom: 40px;
}
h1 {
  color: var(--text-primary-color);
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-size: 50px !important;
  text-align: center;
  margin-bottom: 50px;
}
h3 {
  color: var(--text-primary-color);
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 25px;
}
h4 {
  color: var(--text-primary-color);
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
}
p {
  color: var(--text-primary-color);
  text-align: center;
}

a,
a:hover,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

.separator {
  background-color: var(--text-primary-color);
  height: 2px;
}
.copyright {
  margin-top: 15px;
  margin-bottom: 10px;
}
.footer-container {
  position: relative;
}
.ellipse4 {
  position: absolute;
  left: -800px;
  bottom: 0;
  z-index: 0;
  height: 100%;
}

.light-ellipse {
  left: -200px !important;
}

.link-container {
  z-index: 1;
}
/* extra small & small */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 45px !important;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }

  .col-2 {
    display: none;
  }

  .light-ellipse {
    left: -600px !important;
  }
}

/* medium */
@media screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 45px !important;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 15px;
  }
  p {
    font-size: 15px;
  }

  .col-2 {
    display: none;
  }
  .light-ellipse {
    left: -550px !important;
  }
}
</style>